@import "~antd/dist/antd.less";

.header-nav-bg {
	min-width: 75rem;

	.nav-item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 1rem;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000;
		cursor: pointer;
		flex: 1;
		border-bottom: 2px solid #fff;

		.triangle-down {
			width: 0;
			height: 0;
			margin-left: 0.3125rem;
			border-left: 0.25rem solid transparent;
			border-right: 0.25rem solid transparent;
			border-top: 0.5rem solid @primary-color;

		}

		&:hover {
			border-bottom: 2px solid @primary-color;
			color: @primary-color;
		}
	}

	.redDotPC {
		height: 1.25rem;
		background: #fb3546;
		padding: 0 0.3rem;
		color: #fff;
		font-weight: bold;
		border-bottom-right-radius: 0.625rem;
		border-top-left-radius: 0.625rem;
		border-top-right-radius: 0.625rem;
		cursor: pointer;
	}

	box-shadow: 0rem 0rem 0.375rem rgba(0, 0, 0, 0.16);
	background: #fff;
	position: fixed;
	top: 0;
	z-index: 1099;
	width: 100%;
	padding-left: 1.875rem;

	.ant-menu-horizontal {
		border: none !important;
	}

	.ant-menu-vertical.ant-menu-sub,
	.ant-menu-vertical-left.ant-menu-sub,
	.ant-menu-vertical-right.ant-menu-sub {
		min-width: 90px !important;
	}
}

.pc-container {
	margin-top: 3.125rem;

	.nav-item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 1rem;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		flex: 1;

		.triangle-down {
			width: 0;
			height: 0;
			margin-left: 0.3125rem;
			border-left: 0.25rem solid transparent;
			border-right: 0.25rem solid transparent;
			border-top: 0.5rem solid @primary-color;
		}

		&:hover {
			background: @primary-color;
			color: #fff;
		}
	}

	.w0 {
		width: 100%;
		//width: calc(100vw - 12%);
		//margin-left: 6%;
	}

	.pc-block {

		//padding-top: 7.6rem;
		//min-height:50rem;
		.content-container {
			min-width: 62.5rem;
			background: #ffffff;
			box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.16);
			border-radius: 1rem;
			//margin-left: 0.88rem;
			padding: 2rem;
			position: relative;

			.person-user {
				height: 2.875rem;
				position: absolute;
				bottom: 1.2rem;
				right: 2rem;
				// animation: person 2s infinite;
				display: flex;
				// flex-direction: column;
				align-items: center;

				div {
					font-size: 16px;
					color: #006FFF;
					cursor: pointer;
				}

				img {
					width: 2rem;
					height: 2rem;
					margin: 0 .5rem;
					background-color: red;
					animation: person 2s ease infinite alternate-reverse;
				}
			}

			@keyframes person {
				// from {
				// 	width: 2rem;
				// 	height: 2rem;
				// }

				// to {
				// 	width: 2.6rem;
				// 	height: 2.6rem;
				// }
				0% {
					transform: scale(1);
				}

				100% {
					transform: scale(1.5);
				}
			}

			//min-height: calc(100vh - 15rem);
			.sub-title {
				margin-bottom: 2rem;

				.title {
					margin-bottom: 0 !important;
				}
			}

			.title {
				position: relative;
				font-size: 1.25rem;
				color: #000000;
				margin-bottom: 2rem;
				display: inline-block;
				z-index: 1;

				&::after {
					content: "";
					display: block;
					position: absolute;
					top: 0.875rem;
					width: 100%;
					height: 0.8125rem;
					background: linear-gradient(180deg, rgba(145, 213, 255, 0.12) 0%, #91d5ff 100%);
					z-index: -1;
				}
			}

			.pi-l {
				.edit-info {
					font-size: 0.88rem;
					font-weight: 400;
					color: #004ea2;
					margin-top: 1rem;
				}
			}

			.pi-r {
				width: 50%;
				margin-left: 3rem;

				.item {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 0.88rem;
					font-weight: 400;
					color: #333333;
					margin-bottom: 0.75rem;

					.label {
						min-width: 5rem;
						text-align: right;
						margin-right: 1.25rem;
					}

					.content {
						flex: 1;
					}
				}
			}

			.ant-alert {
				text-align: center;
				width: 50%;
				display: flex;
				flex-direction: row;
				justify-content: center;
			}

			.ant-alert-content {
				flex: none !important;
			}

			.f13 {
				font-size: .8125rem;
			}
		}

		.ant-breadcrumb a {
			color: rgba(0, 0, 0, 0.45) !important;
		}

		.ant-breadcrumb-separator {
			color: rgba(0, 0, 0, 0.45) !important;
		}

		.ant-breadcrumb>span:last-child {
			color: rgba(0, 0, 0, 0.85) !important;
		}

		.ant-radio-wrapper {
			min-width: 0 !important;
			margin-bottom: 0 !important;
		}
	}
}

.pc-bg {
	width: 100%;
	height: 7.5rem;
	background-size: 100% 100%;
	margin-top: 4.3125rem;

	.pc-user {
		//width: 13.75rem;
		//background: #ffffff;
		margin-top: 0.75rem;
		padding: 0.75rem;
		z-index: 88;

		.info {
			display: flex;
			flex-direction: row;

			.name {
				font-size: 1.25rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #fff;
			}

			.red-tip {
				height: 1.25rem;
				background: #fb3546;
				border-radius: 0.625rem 0.625rem 0.625rem 0;
				color: #fff;
				padding: 0 0.3125rem;
				text-align: center;
				margin-left: 0.625rem;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				margin-bottom: 1.25rem;
			}

			.auth-icon {
				height: 1.3125rem;
				background: linear-gradient(180deg, #d8deec 0%, #90a3b0 100%);
				border-radius: 6px;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-left: 1rem;

				.auth-text {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					padding-right: 0.3125rem;
				}
			}
		}

		.verify-info {
			padding: 0 0.625rem;
			display: none;

			.line {
				height: 0.0625rem;
				background-color: #d8deec;
				//margin-top: 1.0625rem;
				margin-bottom: 1.4375rem;
			}

			.verify-content {
				text-align: left;
			}
		}

		.verify-btn {
			background: @primary-color;
			font-size: 0.875rem;
			margin: 1rem;
			padding: 0.3125rem;
			font-weight: 400;
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			border-radius: 0.625rem;
		}
	}

	.logout-icon {
		width: 1.25rem;
		height: 1.25rem;
		background-color: #fff;
	}

	.logout-text {
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		margin-top: 0.5rem;
	}
}

.blue-bg {
	width: 100%;
	height: 3.125rem;
	background-color: #004ea2;
}

.ant-layout-sider {
	background: none !important;
}

.personal-r-container {
	background: #ffffff;
	box-shadow: 0rem 0rem 0rem rgba(0, 0, 0, 0.1);
	//margin-left: 0.88rem;
	//min-height: calc(100vh - 15rem);
	padding: 2rem;

	.title {
		font-size: 1rem;
		font-weight: 600;
		color: #000000;
		margin-bottom: 2rem;
	}
}

.m-sider {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0 0 10px -2px #ccc;
	transition: all 0.3s @ease-in-out;
}

.m-main {
	position: relative;
	height: 100vh;
	overflow: hidden;
	transition: all 0.3s @ease-in-out;

	.m-container {
		//margin-top: 48px;
		padding: 20px;
		height: 100%;
		overflow-y: auto;
		background: #e8e8e8;

		h3 {
			margin-top: 10px;
		}

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			background-color: #ccc;
		}
	}
}

.m-siderwapper {
	overflow-x: hidden;
	overflow-y: auto;
	height: 100vh;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
}

.m-top {
	width: 100%;
	min-height: 10rem;
	background-color: @primary-color;
	margin-top: 3.125rem;
	padding: 1.25rem 1rem;

	.m-avatar {
		width: 4rem;
		min-width: 4rem;
		height: 4rem;
		border-radius: 2rem;
		// background-color: #edeef5;
		margin-right: 1rem;
	}

	.m-name {
		font-size: 1.2rem;
		color: #fff;
		margin-bottom: 1rem;
	}

	.m-label {
		height: 1.375rem;
		line-height: 1.375rem;
		display: inline-block;
		justify-content: center;
		align-items: center;
		background: #e6f7ff;
		border: 1px solid #91d5ff;
		font-size: 12px;
		font-weight: 400;
		color: #1890ff;
		padding: 0 0.3125rem;
		margin-top: 0.4375rem;
		margin-bottom: 1rem;
	}

	.m-comp-name {
		font-size: 0.875rem;
		color: #fff;
		font-weight: 400;
	}
}

.m-menulist {
	// margin: 40px 0;
	transition: all 0.3s @ease-in-out;

	.ant-menu {
		transition: all 0.3s @ease-in-out;
	}
}

.ant-menu-inline {
	border: none;
	background-color: #fff !important;
}

.ant-menu-vertical {
	border: none;
}

.ant-menu-vertical-left {
	border: none;
}

// 我收藏的产品
.collect-product {
	.product-list {
		.product-list-item {
			margin-bottom: 1.875rem;
			background: #ffffff;
			box-shadow: 0 0.0625rem 0.5rem rgba(0, 0, 0, 0.1);
			cursor: pointer;

			&:hover {
				box-shadow: 0px 0px 8px rgba(0, 111, 255, 0.5);
			}

			.top-block {
				padding: 0.75rem 0;

				.left-product-name {
					// width: 15.625rem;
					min-width: 12.5rem;
					margin: 0 1.5rem;

					.logo {
						width: 8.75rem;
						height: 3rem;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.product-name {
						max-width: 80%;
						padding-top: 0.5rem;
						color: #000000;
						font-size: 1.125rem;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}

				.center-product-desc {
					flex: 1;
					max-width: 50%;

					.product-desc-item {
						.key {
							font-size: 1rem;
							color: #999999;
						}

						.val {
							padding-top: 0.5rem;
							font-size: 1.5rem;
							font-weight: bold;
							color: #f8852d;
						}
					}
				}

				.right-product-apply {
					// width: 15.625rem;
					min-width: 12.5rem;
					// margin-left: 4.5rem;
					text-align: center;

					.apply-count {
						padding-top: 0.5rem;
						color: #666666;
					}
				}
			}

			.bot-block {
				height: 2.75rem;
				background: linear-gradient(90deg, #e9f1fe 0%, #ffffff 100%);

				.left-product-tags {

					// width: 50%;
					// border-right: 1px solid #cfd4d9;
					.tag-list {
						margin-left: 3.5rem;

						.tag {
							height: 1.5rem;
							margin-right: 1rem;
							padding: 0 0.3125rem;
							border: 0.0625rem solid #006fff;
							font-size: 0.875rem;
							color: #006fff;
						}
					}
				}

				// .right-product-way {
				// 	width: 50%;
				// 	font-size: 0.875rem;
				// 	color: #8293aa;
				// 	.way-list {
				// 		margin-left: 4.8125rem;
				// 		.way {
				// 			padding-right: 1.5rem;
				// 		}
				// 	}
				// }
			}
		}
	}
}

// 我收藏的政策
.collect-policy {
	.policy-list {
		.policy-list-item {
			position: relative;
			margin-bottom: 1.5rem;
			padding: 1.5rem 0;
			background: #f1f5f7;
			box-shadow: 0px 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
			border-radius: 0.1875rem;
			cursor: pointer;

			&::before {
				content: "";
				position: absolute;
				top: 1.375rem;
				left: 0;
				width: 0.3125rem;
				height: 3.25rem;
				background: #ff4d4f;
			}

			.left-title {
				padding-left: 3.25rem;

				.hd {
					font-size: 1rem;
					font-weight: bold;
					line-height: 31px;
					color: #000000;
				}

				.date {
					padding-top: 1rem;
					font-size: 1rem;
					color: #666666;
				}
			}

			.right-apply {
				padding-right: 3.25rem;
			}
		}
	}
}

// 系统管理/用户管理
.filter-form {
	#filter {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;

		.inputs {
			display: flex;
			flex-wrap: wrap;

			.ant-form-item {
				padding-right: 1.5rem;
			}
		}

		.btns {
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
		}
	}
}

.operation {
	padding: 1.25rem 0;

	.ant-tag {
		cursor: pointer;
	}
}

.ant-modal-content {

	#add,
	#edit {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.ant-form-item {
			width: 46%;

			.ant-col {
				width: 5rem;
			}
		}
	}
}

// 消息中心
.msg-container {
	.msg-list {
		.msg-list-item {
			padding: 0.75rem 0;
			font-size: 1rem;
			border-bottom: 0.0625rem solid #eee;

			.circle {
				width: 0.9375rem;
				height: 0.9375rem;
				margin-right: 0.75rem;
				background: #006fff;
				border-radius: 50%;
			}

			.left-date {
				width: 7.5rem;
				line-height: 1.5rem;
				color: #bdbdbd;
			}

			.right-msg {
				width: 100%;
				line-height: 1.5rem;
				color: #000000;
			}
		}
	}
}

// 政策引领
.my-policy {
	.policy-list-wrap {
		.policy-list {
			flex-wrap: wrap;
			margin-top: 2rem;
			margin-left: 0.875rem;

			.policy-item {
				width: 24%;
				min-height: 17rem;
				background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
				box-shadow: 0px 2px 6px #00000029;
				border-radius: 3px;
				margin-bottom: 1rem;
				margin-right: 1rem;

				&:nth-child(4n) {
					margin-right: 0;
				}

				.item-title {
					width: 100%;
					height: 5rem;
					font-size: 1.25rem;
					font-weight: 600;
					color: #333333;
					background: #f1f5f7;
					border-radius: 3px 3px 0px 0px;
					// cursor: pointer;
					position: relative;

					&::before {
						content: "";
						display: block;
						width: 5px;
						height: 3.25rem;
						background: #ff4d4f;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}

					.title-self {
						border-bottom: 2px solid transparent;
					}

					&:hover {
						.title-self {
							color: #006FFF;
							cursor: pointer;
							transform: scale(1.01);
							transition: all .2s;
							border-bottom: 2px solid #006FFF;
						}
					}
				}

				.item-body {
					width: 100%;
					// cursor: pointer;
					// &:hover{
					//   transform: scale(1.01);
					// }
				}

				.item-information {
					width: 85%;
					// width: 20rem;
					min-height: 4rem;
					padding: 0.75rem 1.25rem;
					// padding-left: 1.25rem;
					font-size: 0.875rem;
					margin: 1rem auto;
					color: rgba(0, 0, 0, 0.65);
					border-radius: 2px;
				}

				.warning-information {
					background: #fffbe6;
					border: 1px solid #ffe58f;
				}

				.success-information {
					background: #f6ffed;
					border: 1px solid #b7eb8f;
				}

				.error-information {
					background: #faeeee;
					border: 1px solid #e94d4d94;
				}

				.item-info {
					width: 100%;
					flex: 1;
					padding: 0 2rem;
					padding-bottom: 1rem;
					font-size: 0.875rem;
					color: #666;
				}
			}
		}
	}

	.policy-refuse-item {
		width: 98%;
		padding: 2.5rem;
		background: #ffffff;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
		border-radius: 5px;
		align-items: flex-start;
		margin: 1.25rem auto;

		.refuse-text {
			font-size: 0.875rem;
			color: #333;
		}

		.refuse-title {
			width: 100%;
			font-size: 1.25rem;
			font-family: Microsoft YaHei;
			font-weight: 600;
			margin-bottom: 0.625rem;
			color: #333333;

			.detail {
				font-size: 1rem;
				color: #1890ff;
				cursor: pointer;
			}
		}

		.refuse-wrap {
			width: 100%;

			.refuse-con {
				width: 48%;
				height: 16.25rem;
				padding: 1.25rem 2rem;
				background: #fff1f0;

				&:first-child {
					padding-right: 0.625rem;
				}

				&:last-child {
					background: #f2f2f2;
				}

				.refuse-reason {
					padding: 1rem 0.875rem;
				}

				.refuse-list {
					height: 11.25rem;
					margin-top: 0.75rem;
					overflow-y: scroll;

					.refuse-item {
						margin-bottom: 0.625rem;

						.circle {
							width: 0.625rem;
							height: 0.625rem;
							flex-shrink: 0;
							background: #ff4d4f;
							border-radius: 50%;
							margin-right: 1rem;
							margin-top: 0.75rem;
						}

						.item-text {
							color: #333;
							line-height: 2rem;
							padding-right: 1.25rem;
						}
					}
				}
			}
		}

		.reapply {
			width: 100%;
			text-align: center;
			margin-top: 2.5rem;
		}
	}
}

// 政策获取
.access-policy {
	.policy-list-wrap {
		.policy-list {
			flex-wrap: wrap;
		}
	}

	.choice-area {
		padding-top: 0.625rem;
		margin-bottom: 2.5rem;
		justify-content: space-evenly;

		.choice {
			color: #666666;

			.label {
				width: 5.75rem;
			}
		}

		.ant-radio-button-wrapper {
			margin-right: 0.75rem;
			border: 1px solid #ffffff;

			span {
				color: #666666;
			}
		}

		.ant-radio-button-wrapper-checked {
			span {
				color: #1890ff;
			}

			border-radius: 3px;
			border: 1px solid #1890ff;
			background: #e6f7ff;
		}

		.ant-radio-button-wrapper:not(:first-child)::before {
			background-color: transparent;
		}
	}
}

.comp_auth {
	min-width: 75rem;

	.block {
		padding: 2.1875rem 0;
		border-bottom: 1px solid #eeeeee;
	}
}

.comp_label {
	min-width: 75rem;

	.logo {
		width: 6.25rem;
		height: 6.25rem;
		border-radius: 6px;
		border: 1px solid #ececec;
		margin-right: 1rem;
	}

	.comp_name {
		font-size: 1.25rem;
		font-weight: 400;
		color: #333333;
	}

	.comp_tag {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.875rem;
		font-weight: 400;
		color: #f8852d;
		border: 1px solid #f8852d;
		border-radius: 1rem;
		padding: 0.1875rem 1.4375rem;
		margin-right: 1rem;
	}

	.file_item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #ecf4ff;
		height: 7.125rem;
		border-radius: 0.375rem;
		cursor: pointer;

		.name {
			font-size: 1rem;
			font-weight: bold;
			color: #1890ff;
			margin-bottom: 1.375rem;
		}

		.tip_label {
			font-size: 14px;
			font-weight: 400;
			color: #7f94a8;
			margin-left: 5px;
		}

		.bg {
			position: absolute;
			bottom: 0;
			right: 0.9375rem;
		}
	}
}

// 资质引领
.qualification-guidance {
	.qualification-list-wrap {

		// height: 30.625rem;
		.item-head {
			box-sizing: border-box;
			padding-top: 1rem;

			.item-title {
				font-size: 1.25rem;
				font-weight: bold;
				color: #000000;
				position: relative;

				&::before {
					content: "";
					display: block;
					width: 0.3125rem;
					height: 3.25rem;
					background: #ff4d4f;
					position: absolute;
					left: -3.45rem;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.ope {
				font-size: 1rem;
				color: #666;
			}

			.answer-status {
				width: 0.625rem;
				height: 0.625rem;
				background: #52c41a;
				border-radius: 50%;
				margin-right: 0.75rem;
			}
		}

		.answer-wrap {

			// margin-top: 2rem;
			.avatar {
				width: 3.375rem;
				height: 3.375rem;
				background: #c4deff;
				border-radius: 50%;
				margin-right: 2rem;
				margin-top: 1rem;
				margin-left: 1rem;
			}

			.answer-con {
				width: 47.25rem;
				// min-height: 16.625rem;
				padding: 2rem;
				background: #ffffff;
				border-radius: 5px;
				position: relative;
				line-height: 2rem;

				&::before {
					content: "";
					width: 0;
					height: 0;
					position: absolute;
					left: -2rem;
					top: 1.625rem;
					border: 1rem solid #fff;
					border-left-color: transparent;
					border-top-color: transparent;
					border-bottom-color: transparent;
				}
			}
		}
	}

	.qualification-item {
		background: #f1f5f7;
		padding: 0px 2.5rem 1rem;
		border-radius: 3px;
	}

	.ant-collapse-item {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	}

	.ant-collapse-header,
	.ant-collapse-content-box {
		background: #f1f5f7;
	}

	.ant-collapse-item {
		margin-bottom: 2rem;
	}

	.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
		top: 70% !important;
		right: 80px !important;
	}

	.standard-content {
		background: #ffffff;
		// margin-top: 3.75rem;
		// margin-bottom: 6.25rem;
		padding: 2.5rem 5rem 0;

		// box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
		.standard-title {
			font-size: 1.5rem;
			color: #000000;
			font-weight: bold;
			text-align: center;
			margin-bottom: 2rem;
		}

		.clause-list {
			.clause-item {
				.clause-order {
					color: #999;
					font-size: 0.875rem;
					margin-bottom: 1.5625rem;
				}

				.clause-tag {
					padding: 2px 4px;
					background: #f1f7ff;
					border-radius: 4px;
					color: #006fff;
				}

				.clause-detail {
					line-height: 2.25rem;
				}
			}
		}
	}

	.radio-box {
		padding-left: 2.5rem;
	}
}

.detail_word {
	position: absolute;
	bottom: 0.5rem;
	right: 25px;
	font-size: 14px;
	font-weight: 600;
	color: #7f94a8;
}

.comp_label_modal {
	.tip {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		margin-top: 0.8125rem;
		margin-bottom: 2.9375rem;
	}

	.submit_btn {
		width: 7.8125rem;
		height: 2.5rem;
		background: #1890ff;
		border-radius: 3px;
		margin: 1.375rem 0;
	}

	.show_file {
		// height: 2rem;
		// line-height: 2rem;
		background: #ffffff;
		border: 1px solid #bbd9ff;
		border-radius: 3px;
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		padding: .3125rem 1.875rem;
		// cursor: pointer;
		margin-bottom: 1.625rem;
		cursor: pointer;
	}

	.status_text {
		font-size: 20px;
		font-weight: 400;
		color: #7f94a8;
		margin-left: 0.4375rem;
	}

	.error_text {
		font-size: 14px;
		font-weight: 400;
		color: #ff0000;
		margin-bottom: 1rem;

		&::before {
			content: "*";
			color: #ff0000;
		}
	}

	.ant-btn>.anticon+span,
	.ant-btn>span+.anticon {
		margin-left: 8px;
		max-width: 23.75rem;
		flex-wrap: wrap;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
	}
}

.sub_title {
	font-size: 1.125rem;
	font-weight: 400;
	color: #666666;
	flex: 1;

	&::after {
		content: "\ff1a";
	}
}

.submit-btn {
	width: 10rem;
	height: 3rem;
	font-size: 1rem;
}

.line-label {
	width: 120px;
	min-width: 120px;
	text-align: right;
}

.asset-title {
	font-size: 0.88rem;
	font-weight: bold;
	color: #333;
	margin: 1rem 1rem 1rem 0;
}

.asset-line {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0.625rem;
}

.block-title {
	font-size: 1rem;
	font-weight: bold;
	color: #333;
	margin-right: 1rem;
	// margin-bottom: 1rem;
}

.detail-section {
	.ant-col-18 {
		max-width: 100%;
	}
}

@media screen and (max-width: 1300px) {
	.content-container {
		.ant-form-item {
			.ant-form-item-label>label {
				word-break: break-all;
				white-space: normal;
				font-size: 13px !important;
			}
		}
	}
}
@primary-color: #006FFF;@layout-header-background: #006FFF;@menu-dark-submenu-bg: #006FFF;@link-color: #006FFF;
//@import "~antd/dist/antd.less";
@import "~nprogress/nprogress.css";

@color-line: #d8d8d8;
@color-main: #6a99fd;

@sider-width: 256px;

// ::-webkit-scrollbar{
//   width: 0;
//   height: 6px;
// }
@import "../fonts/YouSheBiaoTiHei.css";
#nprogress {
  .bar {
    background-color: @primary-color;
  }

  .peg {
    box-shadow: 0 0 10px @primary-color, 0 0 5px @primary-color;
  }

  .spinner-icon {
    border-top-color: @primary-color;
    border-left-color: @primary-color;
  }
}

.u-table-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.page-head {
  position: relative;
  padding: 15px 40px 10px 25px;
  background-color: #fff;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: #bbb;
    transform: scaleY(0.1);
  }
}

.page-content {
  // margin-top: 20px;
  padding: 40px;
  background-color: #fff;
}

// 错位页面
.pnf-wrapper {
  margin: 200px auto;
  width: 400px;

  &--title {
    color: #222;
    font-size: 60px;
    font-style: italic;
    font-weight: bold;
  }
  &--subtitle {
    margin-top: 4px;
    color: #666;
    font-size: 16px;
    white-space: nowrap;

    strong {
      font-size: 28px;
      margin-right: 8px;
    }
  }
  &--tip {
    margin-top: 20px;
    color: #333;
    font-size: 14px;

    a {
      color: @primary-color;
    }
  }
}

html {
  font-size: 16px;
}

body {
  font-family: Microsoft YaHei;
}

.r {
  display: flex;
  flex-direction: row;
}
.c {
  display: flex;
  flex-direction: column;
}
.w {
  display: flex;
  flex-wrap: wrap;
}
.rc {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rsc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rcc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.csc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.ca {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.cac {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.ccc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fav-icon {
  width: 0.76rem;
  height: 0.76rem;
  margin-right: 0.31rem;
}

.w1200 {
  width: 75rem;
  margin: 0 auto;
}
.w80 {
  min-width: 75rem;
  width: 80vw;
  margin: 0 auto;
}
.lineH {
  width: 100%;
  height: 0.0625rem;
  background: #d1d1d1;
}
.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// style={{"WebkitBoxOrient": "vertical"}}
.twoLine {
  overflow: hidden;
  text-overflow: ellipsis;

  /* Safari, Opera, and Chrome */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */

  /* Firefox */
  display: -moz-box;
  -moz-box-orient: horizontal;

  /* Safari, Opera, and Chrome */
  // display:-webkit-box;
  // -webkit-box-orient:horizontal;

  /* W3C */
  display: box;
  box-orient: horizontal;
}

.detail-count {
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 20px;
}

.table-action {
  color: @primary-color;
  cursor: pointer;
}

.ant-descriptions-item-label {
  color: #999 !important;
}
.ant-descriptions-item-content {
  color: #333 !important;
  font-weight: 400 !important;
}

.ant-table-tbody {
  > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background: #e6f6ff !important;
  }
}
.ant-table-fixed {
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background: #e6f6ff !important;
  }
}

// .avatar-uploader > .ant-upload {
//   width: 8rem;
//   height: 8rem;
// }

.amap-logo {
  z-index: 80 !important;
}

.amap-copyright {
  z-index: 80 !important;
}

// 设置菜单样式
.ant-menu,
.ant-menu-sub,
.ant-menu-inline {
  // color: red;
  // background-color: #044d50 !important;
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  background: linear-gradient(90deg, #e8f7ff 0%, #ffffff 100%) !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0 !important;
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-bottom: 0 !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border: none !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  background-color: #fff !important;
  border: none !important;
}

.ant-menu-item-selected {
  a::before {
    content: "\25cb" !important;
    left: 1.875rem !important;
  }
}

// .ant-form-item-label {
//   display: flex;
//   justify-content: flex-end;
//   line-height: 16px;  //这个数值视具体情况而定
//   label {            //这是关键
//     white-space: normal;
//     text-align: right;
//     // color: #8a8a8a;
//     padding-right: 32px;
//     // font-size: 14px;
//     &:after {
//       content: none !important;      //解决上面的样式label后面会有空格
//     }
//   }
// }

.ant-alert {
  text-align: center;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ant-alert-content {
  flex: none !important;
}

.adm-popup-body {
  background-color: #fff;
}

.adm-picker-header {
  background-color: #fff;
}

//des-item
.ant-descriptions-item-label {
  color: #000 !important;
}

//disabled color
.ant-input[disabled] ,.ant-cascader-picker-disabled,.ant-input-number-disabled .ant-input-number-input,.ant-picker-input > input[disabled],.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  color: #333 !important;
}


.comp_item{
  cursor: pointer;
  margin-bottom: 10px;
  &:hover{
      color: @primary-color;
  }
}

.adm-cascader{
  background-color: #fff !important;
}
@primary-color: #006FFF;@layout-header-background: #006FFF;@menu-dark-submenu-bg: #006FFF;@link-color: #006FFF;
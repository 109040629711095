body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-message{
  z-index: 9999 !important;
}

.ant-picker-dropdown{
  z-index: 1200 !important;
}

/* 全局隐藏数字输入框右侧上下箭头 */
.ant-input-number-handler-wrap{
  display:none
}

/*整个滚动条*/
::-webkit-scrollbar {
  
  width: 5px;
  
  height: 8px;
  
  background-color: transparent;
  
}
  
/*定义滚动条轨道*/
  
::-webkit-scrollbar-track {
  
  background-color: #ccc;
  
}
  
/*定义滑块*/
  
::-webkit-scrollbar-thumb {
  
  background-color: rgba(102, 102, 102, 0.651);
  
  border-radius: 4px;
  
}


/* 
.adm-list{
  --border-bottom:none !important
} */
